
import { computed, defineComponent, reactive } from 'vue';
import dayjs from 'dayjs';
import { useQuery, useQueryLoading } from '@vue/apollo-composable';

import { promoteQuery } from '@/apollo/queries';
import {
  PromoteUserTypeEnum,
  PromoteUserTypeMapping,
  WalletTypeEnum,
  HistoryTypeMapping,
} from '@/view-models/promote.vm';
import { formatDate, commafy } from '@/utils/formatter';

import VanFieldPicker from '@/components/VanFieldPicker.vue';
import VanFieldDateRange from '@/components/VanFieldDateRange.vue';

export default defineComponent({
  components: {
    VanFieldPicker,
    VanFieldDateRange,
  },
  setup() {
    const targetOptions = [
      { text: '累積購物金總額', value: WalletTypeEnum.all },
      // { text: '已享有的購物金', value: WalletTypeEnum.received },
      { text: '待生效購物金', value: WalletTypeEnum.waitingEffected },
    ];
    const filter = reactive({
      walletTypeId: WalletTypeEnum.all,
      dateRange: [
        dayjs()
          .startOf('month')
          .toDate(),
        dayjs()
          .endOf('day')
          .toDate(),
      ],
    });
    const pageInfo = reactive({
      index: 1,
      size: 10,
      amount: 0,
    });
    const isShowAll = computed(() => filter.walletTypeId === WalletTypeEnum.all);
    // const isShowReceived = computed(() => filter.walletTypeId === WalletTypeEnum.received);
    const isShowWaitingEffected = computed(() => filter.walletTypeId === WalletTypeEnum.waitingEffected);

    const { result, onResult } = useQuery<{ userWalletHistories: any }>(promoteQuery.getUserWalletHistories, () => ({
      param: {
        walletTypeId: filter.walletTypeId || null,
        dateCreatedStart: filter.dateRange[0],
        dateCreatedEnd: filter.dateRange[1],
      },
      page: {
        index: pageInfo.index,
        size: pageInfo.size,
      },
    }));
    const userWalletHistories = computed(() => result.value?.userWalletHistories.items ?? []);
    onResult((ret) => {
      const { page } = ret.data.userWalletHistories;
      pageInfo.amount = page.dataAmount;
    });

    const { result: result2 } = useQuery<{ userWalletDashboard: any }>(promoteQuery.getUserWalletDashboard, () => ({
      param: {
        walletTypeId: filter.walletTypeId || null,
        dateCreatedStart: filter.dateRange[0],
        dateCreatedEnd: filter.dateRange[1],
      },
    }));
    const userWalletDashboard = computed(() => result2.value?.userWalletDashboard ?? null);
    const isLoading = useQueryLoading();

    function getPromoteUserType(type: string) {
      switch (type) {
        case PromoteUserTypeEnum.consumer:
          return 'primary';
        case PromoteUserTypeEnum.deliveryDriver:
          return 'success';
      }
    }

    return {
      PromoteUserTypeMapping,
      HistoryTypeMapping,
      filter,
      pageInfo,
      targetOptions,
      userWalletHistories,
      userWalletDashboard,
      isLoading,
      isShowAll,
      // isShowReceived,
      isShowWaitingEffected,

      formatDate,
      commafy,
      getPromoteUserType,
    };
  },
});
